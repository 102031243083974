import "./editfavorites.css"
import { memo, useCallback, useEffect, useState } from 'react'
import update from 'immutability-helper'
import { SourceBox } from './SourceBox.js'
import { StatefulTargetBox as TargetBox } from './TargetBox.js'
import RecordCard from "../RecordCard/RecordCard"
import RecordSource from "./RecordSource"
import RecordTarget from "./RecordTarget"
import { useQuery } from "@tanstack/react-query"
import { makeRequest } from "../../axios"
import baseUrl from "../../baseUrl"

export const EditFavorites = memo(function EditFavorites({favoritesCallback, searchResult, favoriteRecords}) {

    const [sourceRecordId, setSourceRecordId] = useState(null)
    const [targetRecordId, setTargetRecordId] = useState(null)

    const [droppedRecords, setDroppedRecords] = useState([])
    const [favorites, setFavorites] = useState(favoriteRecords)
    


    const moveRecord = useCallback((dragIndex, hoverIndex, item) => {
      if(item.lastDroppedItem) {
        favoriteRecords = update(favoriteRecords, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, favoriteRecords[dragIndex]],
          ],
        })
        setFavorites(favoriteRecords)
        favoritesCallback(favoriteRecords)
      }
      
      
      }, 
      []
    )

    const setNewFavorite = useCallback(
      (index, item) => {
        if(typeof(item.lastDroppedItem) == 'undefined')
        {
          const { record } = item
          setDroppedRecords(
            update(droppedRecords, record ? { $push: [record] } : { $push: [] }),
          )
        
          favoriteRecords = update(favoriteRecords, {
              [index]: {
                  $set: item,
              },
            })

            setFavorites(favoriteRecords)
            favoritesCallback(favoriteRecords)
        }
        
      },
      [droppedRecords, favorites],
    )


    const bluesDrops = useQuery(["blues_records"]).data
    const rockDrops = useQuery(["rock_records"]).data
    const jazzDrops = useQuery(["jazz_records"]).data
    const otherDrops = useQuery(["other_records"]).data
    
    let acceptedDrops = []
    if(bluesDrops && rockDrops && jazzDrops && otherDrops)
    {
      acceptedDrops = [...bluesDrops, ...rockDrops, ...jazzDrops, ...otherDrops].map((res) => res[0].toString())
    }

    

    const idQuery = useQuery(["recordIds"], () =>
        makeRequest.get("https://"+baseUrl+"/api/recordid", {mode: "cors"}).then((res) => {
            return res.data;
        })
     )
     

     let accept = ["737"]
     useEffect(() =>{
      const queryData = idQuery.data?.map((s) => s[0].toString()) //idQuery.data.map((s) =>s.toString()) : "1"
      if(idQuery.data){
       accept = queryData
      }
     })

    
     const results = accept.filter(({ value: id1 }) => !acceptedDrops.some(({ value: id2 }) => id2 === id1));
   

    
  return (
    <div className="editFavoritesDnDArea" style={{ overflow: 'hidden', clear: 'both' }}>
      <div className="editFavoritesDnDSource" >
        
        {searchResult?.map((data, index) =>{
          
            return (
                <RecordSource 
                  key={data[0]} 
                  data={data} 
                  setSourceRecordId={setSourceRecordId} 
                  type={data[0].toString()}
                  index={index}
                />
                
            )
        })}
        
      </div>

      <div className="editFavoritesDnDTarget" >
        <div className="editFavoritesTargetText">
          Dra och släpp för att lägga till favorit. Ändra ordningen genom att byta plats på albumen.
        </div>
        <div className="editFavoritesTargetBoxes">
          {idQuery.data && favorites?.map((data, index) =>{
           
              return (
                  <RecordTarget 
                    key={index} 
                    onDrop={(item) => setNewFavorite(index, item)} 
                    lastDroppedItem={data}
                    accept={idQuery.data?.map((s) => s[0].toString())}
                    index={index}
                    type={data[0].toString()}
                    moveRecord={moveRecord}
                    />
              )
          })}
        </div>
        
      </div>
    </div>
  )
})