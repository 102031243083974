import { Link, useLocation, useNavigate } from "react-router-dom"
import "./navbar.css"
import logo from "../../assets/images/logo.png"
import { useContext, useEffect, useRef, useState } from "react"
import { AuthContext } from "../../context/authContext"
import SearchBar from "../SearchBar/SearchBar"
import MobileSearchBar from "../MobileSearchBar/MobileSearchBar"
import { useQuery } from "@tanstack/react-query"
import Dropdown from "../Dropdown/Dropdown"
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu"
import MediaQuery from 'react-responsive'

//import { ReactComponent as HamburgerIcon } from "../../assets/icons/menu.svg";
import HamburgerIcon from "../../assets/icons/menu.svg";
import SearchIcon from "../../assets/icons/search.svg";
import PlusIcon from "../../assets/icons/plus-black.svg";


export default function Navbar({data}){
    const {user, logout} = useContext(AuthContext)

    const navigate = useNavigate();
    const locationData = useLocation();
    const { pathname } = locationData;
    const { state } = locationData;
    const splitLocation = pathname.split("/");

    const [searchResult, setSearchResult] = useState(null)
    const [searchField, setSearchField] = useState(null)

    const popupRef = useRef(null)
    const [showPopup, setShowPopup] = useState(false)
    const [popupText, setPopupText] = useState("")
    const [popupSubText, setPopupSubText] = useState("")

    const [menuOpen, setMenuOpen] = useState(false)

    const mobileSearchBarRef = useRef(null)
    const [showMobileSearchBar, setShowMobileSearchBar] = useState(false)
    const [mobileSearchBarAnimationEnd, setMobileSearchBarAnimationEnd] = useState(false)

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    const handleClickOutside = (e) => {
        if (mobileSearchBarRef.current && !mobileSearchBarRef.current.contains(e.target)) {
            setShowMobileSearchBar(false)
        }
      };
    

    useEffect(()=>{
        if(state && (state.about || state.artist)){
            const text = state.about ? 
                 "Om oss har uppdaterats!"
                :  
                    //"Recensionen " + state.artist + " " + state.record + " har publicerats!"
                    setPopupText("Ny recension har publicerats!") 
                    setPopupSubText(state.artist + " - " + state.record)
            //setPopupText(text)
            setShowPopup(true)
        
            setTimeout(function() {
                setShowPopup(false)
                }, 3000);

            setTimeout(function() {
                navigate("/", {replace:true}) // Remove state after popup has been shown.
                }, 4000);
             
        }
        
    },
        
    [navigate])

    function openMobileSearchBar(){
        setShowMobileSearchBar(true)
        //document.getElementById("searchbarid").focus()
    }

    const handleSearchNavigation = () => {
        if (searchField) {
            navigate("/search", { key: searchField, state: { data: { records: searchResult, searchField: searchField } } });
            setSearchField(null);
            setShowMobileSearchBar(false);
        }
    };

    useEffect(() => {
        handleSearchNavigation();
    }, [searchField, searchResult]);

    /*if(searchField){
        navigate("/search", {key:searchField, state:{data:{records:searchResult, searchField:searchField}}})
        setSearchField(null)
        setShowMobileSearchBar(false)
    }*/

    const animated = document.querySelector(".mobileSearchBarContainer");

    if(mobileSearchBarAnimationEnd)
        document.getElementById("searchbarid").focus()

    
    

    return (
        <div className="navbar">
            <Link className="navbarLogo" to="/" > <img src={logo}  /> </Link>
            <div className="navbarLeft">

                <MediaQuery maxWidth={600}>
                    
                    <div className="mobileSearchBarContainer" ref={mobileSearchBarRef}
                        
                    >
                        <MobileSearchBar 
                            setSearchResult={setSearchResult} 
                            setSearchField={setSearchField} 
                            show={showMobileSearchBar}
                        />
                    </div>

                    {showMobileSearchBar && // Darken the background of the screen when searching
                        <div style={{
                            position:"absolute",
                            left:0,
                            width:"800px",
                            height:"2000px",
                            backgroundColor:"#121212CC",
                            
                            
                            zIndex:5
                        }} >

                        </div>
                    }

                    <div className="navBarHamburgerMenu">
                        <HamburgerMenu 
                            width='9.4vw'
                            height='30px'
                            iconSize='20px'
                            setOpen={setMenuOpen}
                            open={menuOpen}
                            trigger={<img src={HamburgerIcon} className="navBarHamburgerIcon" />}
                            /*trigger={<i className="navbarHamburger fa-solid fa-bars"></i>}*/
                            
                            
                        />
                    </div>
                </MediaQuery>

                <MediaQuery minWidth={601} maxWidth={1000}> {/* TABLET */}
                    <div className="mobileSearchBarContainer" ref={mobileSearchBarRef}>
                        <MobileSearchBar 
                            setSearchResult={setSearchResult} 
                            setSearchField={setSearchField} 
                            show={showMobileSearchBar}
                        />
                    </div>
    
                    {showMobileSearchBar && // Darken the background of the screen when searching
                        <div style={{
                            position:"absolute",
                            left:0,
                            width:"800px",
                            height:"2000px",
                            backgroundColor:"#121212CC",
                            
                            
                            zIndex:5
                        }} >

                        </div>
                    }

                    <div className="navBarHamburgerMenu">
                        <HamburgerMenu
                            width='9.4vw'
                            iconSize='20px'
                            setOpen={setMenuOpen}
                            open={menuOpen}
                            trigger={<img src={HamburgerIcon} className="navBarHamburgerIcon" />}
                            /*trigger={
                                <div className="recordsDropdownCurrentSort"> 
                                    <p style={{marginLeft:"0.5vw"}}> {sortState} </p>
                                    <DropdownArrow className="recordsDropdownArrow" />
                                </div>
                            }*/
                            menu={[
                                <div >BLUES</div>,
                                <div >ROCK</div>,
                                <div >JAZZ</div>,
                                <div >FLER GENRER</div>,
                                <div >OM OSS</div>
                            ]}
                            
                        />
                    </div>
                </MediaQuery>

                <MediaQuery minWidth={1001} maxWidth={1599}> {/* SMALL WEB */}
                    {user && 
                        <div className="navbarUserControl">
                            <Link to="/profile" className="navbarUserProfileLink">
                                <img src={"/profile_pictures/"+user.profilePic} />
                            </Link>
                        </div>
                    
                    }

                    <ul className="navbarListLeft">
                        <li className={["navbarListItem", splitLocation[1] === "blues" ? "active" : ""].join(' ')}> 
                            <Link className="link" to="/blues">BLUES</Link> 
                        </li>
                        <li className={["navbarListItem", splitLocation[1] === "rock" ? "active" : ""].join(' ')}> 
                            <Link className="link" to="/rock">ROCK</Link>
                        </li>
                        <li className={["navbarListItem", splitLocation[1] === "jazz" ? "active" : ""].join(' ')}> 
                            <Link className="link" to="/jazz">JAZZ</Link>
                        </li>
                    </ul>
                </MediaQuery>
                    

                <MediaQuery minWidth={1600}>
                    {user && 
                    
                        <div className="navbarUserControl">
                            <Link to="/profile" className="navbarUserProfileLink">
                                <img src={"/profile_pictures/"+user.profilePic} />
                            </Link>
                            <div className="navbarAddReview">  
                                <Link to="/add" className="navbarAddLink">
                                    <button className="navbarNewReviewButton">
                                        <img src={PlusIcon} className="navbarNewReviewIcon"/>
                                        <p>Ny recension</p>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    
                    }

                    <ul className="navbarListLeft">
                        <li className={["navbarListItem", splitLocation[1] === "blues" ? "active" : ""].join(' ')}> 
                            <Link className="link" to="/blues">BLUES</Link> 
                        </li>
                        <li className={["navbarListItem", splitLocation[1] === "rock" ? "active" : ""].join(' ')}> 
                            <Link className="link" to="/rock">ROCK</Link>
                        </li>
                        <li className={["navbarListItem", splitLocation[1] === "jazz" ? "active" : ""].join(' ')}> 
                            <Link className="link" to="/jazz">JAZZ</Link>
                        </li>
                    </ul>
                </MediaQuery>
            </div>
            

           

            <div className="navbarRight">
                <MediaQuery maxWidth={600}>
                    <img src={SearchIcon} className="navbarSearchIcon" onClick={openMobileSearchBar} />
                    {/*<i className="navbarSearchIcon fa-solid fa-magnifying-glass" onClick={openMobileSearchBar}></i>*/}
                </MediaQuery>

                <MediaQuery minWidth={601} maxWidth={1000}> {/* TABLET */}
                    <img src={SearchIcon} className="navbarSearchIcon" onClick={openMobileSearchBar} />
                </MediaQuery>

                <MediaQuery minWidth={1001} maxWidth={1599}> {/* SMALL WEB */}
                    <ul className="navbarListRight">
                        <li className={["navbarListItem", splitLocation[1] === "annat" ? "active" : ""].join(' ')}> 
                            <Link className="link" to="/annat">FLER GENRER</Link>
                        </li>
                        <li className={["navbarListItem", splitLocation[1] === "about" ? "active" : ""].join(' ')}> 
                            <Link className="link" to="/about">OM OSS</Link>
                        </li>
                    </ul>

                    <img src={SearchIcon} className="navbarSearchIcon" onClick={openMobileSearchBar} />

                    <div className="mobileSearchBarContainer" ref={mobileSearchBarRef}
                        
                    >
                        <MobileSearchBar 
                            setSearchResult={setSearchResult} 
                            setSearchField={setSearchField} 
                            show={showMobileSearchBar}
                        />
                    </div>

                    <div className="navbarPopup" 
                        style={{
                            opacity: showPopup ? 1 : 0,
                            top: showPopup ? "0" : "-20vh",
                            
                            transition: "opacity 2s, top 2s",
                            
                        }}
                    >
                        <p className="navbarPopupHeader">{popupText}</p>
                        {popupSubText != "" && 
                            <p className="navbarPopupSubText">{popupSubText}</p>
                        }
                    </div>  

                    
                    
                    {/*<div className="navBarSearchBarContainer"> TODO: SEARCH
                        <SearchBar setSearchResult={setSearchResult} setSearchField={setSearchField} />
                    </div>
                    <div className="navbarPopup" 
                        style={{
                            opacity: showPopup ? 1 : 0,
                            top: showPopup ? "2vh" : "-10vh",
                            
                            transition: "opacity 2s, top 2s",
                            
                        }}
                    >
                        {popupText}
                    </div>*/}   
                </MediaQuery>

                <MediaQuery minWidth={1600}>
                    <ul className="navbarListRight">
                        <li className={["navbarListItem", splitLocation[1] === "annat" ? "active" : ""].join(' ')}> 
                            <Link className="link" to="/annat">FLER GENRER</Link>
                        </li>
                        <li className={["navbarListItem", splitLocation[1] === "about" ? "active" : ""].join(' ')}> 
                            <Link className="link" to="/about">OM OSS</Link>
                        </li>
                    </ul>
                    
                    <div className="navBarSearchBarContainer">
                        <SearchBar setSearchResult={setSearchResult} setSearchField={setSearchField} />
                    </div>
                    <div className="navbarPopup" 
                        style={{
                            opacity: showPopup ? 1 : 0,
                            top: showPopup ? "2vh" : "-25vh",
                            
                            transition: "opacity 2s, top 2s",
                            
                        }}
                    >
                        <p className="navbarPopupHeader">{popupText}</p>
                        {popupSubText != "" && 
                            <p className="navbarPopupSubText">{popupSubText}</p>
                        }
                    </div>   
                </MediaQuery>   
            </div>
            
        </div>
    )
}