import LatestSection from "../latestsection/LatestSection"
import "./latestrecords.css"

export default function LatestRecords(){
    return (
        <div className="latestRecords">
            <p className="latestRecordsHeader">Senaste recensionerna</p>
            <LatestSection genreOne={"blues"} genreTwo={"rock"} />
            <LatestSection genreOne={"jazz"} genreTwo={"annat"} />
        </div>
    )
}