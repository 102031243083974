import "./rock.css"
import React, { useState, useMemo, useEffect } from 'react';
import axios from "axios"
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { makeRequest } from "../../axios"

import Record from "../../components/record/Record"
import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";
import Records from "../../components/records/Records";
import Navbar from "../../components/Navbar/Navbar";

const Rock = () => {
   
     return (
        <div className="rock">
            <Navbar />
            <Records genre="rock" />
            <Footer />    
        </div>
     )
}

export default Rock;