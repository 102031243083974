import "./footer.css"
import blockazzLogo from "../../assets/images/blockazz_logo.png"
import logo from "../../assets/images/logo.png"
import { Link } from "react-router-dom"
import MediaQuery from "react-responsive"

export default function Footer() {
    return (
        <div className="footer">
            <MediaQuery maxWidth={600}>
            <Link className="mobileFooterLogo" to="/"> <img src={logo}  /> </Link>
            <div className="mobileFooterInfo">
                <p className="mobileFooterHeader">Blockazz Records</p>
                <p className="mobileFooterText">

                    Blockazz Records är sidan som recenserar och tipsar om både 
                    nya och äldre skivor inom Blues, Rock, Jazz och andra genrer 
                    som till exempel Soul, Americana och New age. 
                    <br/>
                    <br/>
                    Vi som skriver på sidan är två bröder som med ett stort 
                    musikintresse vill dela med oss och inspirera andra. 
                    Samtliga skivor kommer från våra egna samlingar. Du kan 
                    läsa mer om bakgrunden och idén till sidan under fliken ”Om oss”. 
                    <br/>
                    <br/>
                    Trevlig läsning och lyssning!

                </p>
                <div className="mobileFooterHorizontalLine"></div>
                <p className="mobileFooterContactHeader">Kontakt</p>
                <p className="mobileFooterEmail">crunbalk@gmail.com</p>
                <p className="mobileFooterEmail">hansrunbalk@gmail.com</p>
                
            </div>
            <div className="mobileFooterCopyright">
                <p>© 2024 Blockazz Records. All Rights Reserved.</p>
            </div>

            </MediaQuery>

            <MediaQuery minWidth={601} maxWidth={1000}> {/* TABLET */}
                <Link className="mobileFooterLogo" to="/"> <img src={logo}  /> </Link>
                <div className="mobileFooterInfo">
                    <p className="mobileFooterHeader">Blockazz Records</p>
                    <p className="mobileFooterText">
                    Blockazz Records är sidan som recenserar och tipsar om både 
                    nya och äldre skivor inom Blues, Rock, Jazz och andra genrer 
                    som till exempel Soul, Americana och New age. 
                    <br/>
                    <br/>
                    Vi som skriver på sidan är två bröder som med ett stort 
                    musikintresse vill dela med oss och inspirera andra. 
                    Samtliga skivor kommer från våra egna samlingar. Du kan 
                    läsa mer om bakgrunden och idén till sidan under fliken ”Om oss”. 
                    <br/>
                    <br/>
                    Trevlig läsning och lyssning!
                    </p>
                    <div className="mobileFooterHorizontalLine"></div>
                    <p className="mobileFooterContactHeader">Kontakt</p>
                    <p className="mobileFooterEmail">crunbalk@gmail.com</p>
                    <p className="mobileFooterEmail">hansrunbalk@gmail.com</p>
                    
                </div>
                <div className="mobileFooterCopyright">
                    <p>© 2024 Blockazz Records. All Rights Reserved.</p>
                </div>
            </MediaQuery>

            <MediaQuery minWidth={1001} maxWidth={1599}>
                <Link className="footerLogo" to="/"> <img src={logo}  /> </Link>
                <p className="footerInfoHeader">Blockazz Records</p>
                <div className="footerInfo">
                    Blockazz Records är sidan som recenserar och tipsar om både 
                    nya och äldre skivor inom Blues, Rock, Jazz och andra genrer 
                    som till exempel Soul, Americana och New age. 
                    <br/>
                    <br/>
                    Vi som skriver på sidan är två bröder som med ett stort 
                    musikintresse vill dela med oss och inspirera andra. 
                    Samtliga skivor kommer från våra egna samlingar. Du kan 
                    läsa mer om bakgrunden och idén till sidan under fliken ”Om oss”. 
                    <br/>
                    <br/>
                    Trevlig läsning och lyssning!
                </div>
                <div className="mobileFooterHorizontalLine"></div>
                <div className="footerContact">
                    <p className="footerContactHeader">Kontakt</p>
                    <div className="footerEmails">
                        <div className="footerInvisibleDiv"></div>
                        <p className="footerEmail">crunbalk@gmail.com</p>
                        <p className="footerDotSeparator"></p>
                        <p className="footerEmail">hansrunbalk@gmail.com</p>
                    </div>
                </div>
                
                <div className="footerBottomSection">
                    <p className="footerCopyright">
                        © 2024 Blockazz Records. All Rights Reserved.
                    </p>
                </div>
            </MediaQuery>

            <MediaQuery minWidth={1600}>
                <Link className="footerLogo" to="/"> <img src={logo}  /> </Link>
                <p className="footerInfoHeader">Blockazz Records</p>
                <div className="footerInfo">
                    Blockazz Records är sidan som recenserar och tipsar om både 
                    nya och äldre skivor inom Blues, Rock, Jazz och andra genrer 
                    som till exempel Soul, Americana och New age. 
                    <br/>
                    <br/>
                    Vi som skriver på sidan är två bröder som med ett stort 
                    musikintresse vill dela med oss och inspirera andra. 
                    Samtliga skivor kommer från våra egna samlingar. Du kan 
                    läsa mer om bakgrunden och idén till sidan under fliken ”Om oss”. 
                    <br/>
                    <br/>
                    Trevlig läsning och lyssning!
                </div>
                <div className="mobileFooterHorizontalLine"></div>
                <div className="footerContact">
                    <p className="footerContactHeader">Kontakt</p>
                    <div className="footerEmails">
                        <div className="footerInvisibleDiv"></div>
                        <p className="footerEmail">crunbalk@gmail.com</p>
                        <p className="footerDotSeparator"></p>
                        <p className="footerEmail">hansrunbalk@gmail.com</p>
                    </div>
                </div>
                
                <div className="footerBottomSection">
                    <p className="footerCopyright">
                        © 2024 Blockazz Records. All Rights Reserved.
                    </p>
                </div>
            </MediaQuery>
        </div>
    )
}