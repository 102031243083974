import "./records.css"
import React, { useState, useMemo } from 'react';
//import data from "../../assets/mock_data.json";
import Pagination from "../pagination/Pagination";
import { useQuery } from "@tanstack/react-query"
import { makeRequest } from "../../axios";
import Navbar from "../Navbar/Navbar";
import RecordCard from "../RecordCard/RecordCard";
import { useEffect } from "react";
import Footer from "../footer/Footer";
import Dropdown from "../Dropdown/Dropdown";
import MediaQuery from "react-responsive";
import MobileSortMenu from "../MobileSpecific/MobileSortMenu/MobileSortMenu";

import SortIcon from "../../assets/icons/menu-scale-black.svg";
import DownArrow from "../../assets/icons/nav-arrow-down-black.svg";

import baseUrl from "../../baseUrl"
import { useLocation } from "react-router-dom";

let PageSize = 30;

export default function Records({ genre, reset }) {

    const [currentPage, setCurrentPage] = useState();
    
    const [sortState, setSortState] = useState("");
    const [sortStateDisplayString, setSortStateDisplayString] = useState("");
    const [sortMenuOpen, setSortMenuOpen] = useState(false)
    const [recordData, setRecordData] = useState([])

    const [isFirstTimeSorted, setIsFirstTimeSorted] = useState(false)

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
        let currentPageFromQueryParam = queryParams.get('currentPage');

    

    
    
    useEffect(() => {
        
        if(currentPageFromQueryParam){
            setCurrentPage(currentPageFromQueryParam)
            currentPageFromQueryParam = null
        }
        console.log("currentpage: " + currentPageFromQueryParam)
    }, [currentPageFromQueryParam])
    

    const { isLoading, data } = useQuery([genre+"records"], () =>
        makeRequest.get("https://"+baseUrl+"/api/records", {mode: "cors", params: {genre: genre}}).then((res) => {
            //setCurrentPage(1) // reset currentPage when genre is changed
            return res.data;
        })
    );

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
    }, [currentPage])

    useEffect(() => {
        console.log("useeffect data")
        if(!isLoading){
            const sortLatest = [...data]
            sortLatest.sort((a, b) => {
                
                return new Date(b[8]) - new Date(a[8])// ? -1 : 1
            })

            setRecordData(sortLatest)
            
            setSortState("latest")
            setSortStateDisplayString("Senaste recension")
            
        }
    },[data])


    useEffect(() =>{
        //setSortState("latest")
        //setSortStateDisplayString("Senaste recension")
        
        if(sortState == "latest"){
            handleLatest()
        }
        else if(sortState == "artistDesc"){
            handleArtistDescending()
        }
        else if(sortState == "artistAsc"){
            handleArtistAscending()
        }
        else if(sortState == "recordDesc"){
            handleRecordDescending()
        }
        else if(sortState == "recordAsc"){
            handleRecordAscending()
        }
    }, [sortState])
    

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return (recordData) ? recordData.slice(firstPageIndex, lastPageIndex) : "loading...";
    }, [currentPage, recordData]);

    console.log("memo")
    console.log(currentTableData)

    const handleLatest = () => {
        if(!isLoading){
            const sortLatest = [...recordData]
            sortLatest.sort((a, b) => {
                
                return new Date(b[8]) - new Date(a[8])// ? -1 : 1
            })
            setRecordData(sortLatest)
            setSortState("latest")
            setSortStateDisplayString("Senaste recension")
            setCurrentPage(1)
        }
    }

    const handleArtistAscending = () => {
        if(!isLoading){
            const sortAsc = [...recordData]
            sortAsc.sort((a, b) => {
                return a[1] > b[1] ? 1 : -1;
            })
            setRecordData(sortAsc)
            setSortState("artistAsc")
            setSortStateDisplayString("Artist A-Ö")
            setCurrentPage(1)
        }
        
    }

    const handleArtistDescending = () => {
        if(!isLoading){
            const sortDesc = [...recordData]
            sortDesc.sort((a, b) => {
                return a[1] > b[1] ? -1 : 1;
            })
            setRecordData(sortDesc)
            setSortStateDisplayString("Artist Ö-A")
            setSortState("artistDesc")
            setCurrentPage(1)
        }
    }

    const handleRecordAscending = () => {
        if(!isLoading){
            const sortAsc = [...recordData]
            sortAsc.sort((a, b) => {
                return a[2] > b[2] ? 1 : -1;
            })
            setRecordData(sortAsc)
            setSortState("recordAsc")
            setSortStateDisplayString("Skiva A-Ö")
            setCurrentPage(1)
        }
    }

    const handleRecordDescending = () => {
        if(!isLoading){
            const sortAsc = [...recordData]
            sortAsc.sort((a, b) => {
                return a[2] > b[2] ? -1 : 1;
            })
            setRecordData(sortAsc)
            setSortState("recordDesc")
            setSortStateDisplayString("Skiva Ö-A")
            setCurrentPage(1)
        }
    }

   
    //console.log(recordData)
    
    return (
        <div className="records">
            <Navbar />

            <MediaQuery maxWidth={600}>
                <div className="recordsTopInfoTop">
                    <div className="recordsGenre">
                        { genre.toLowerCase() != "annat" ? 
                            genre.toUpperCase().charAt(0).toUpperCase() + //Capitalize first letter
                                                    genre.slice(1) + "recensioner"
                            : 
                            "Fler Genrer"
                        }
                           
                    </div>

                        {genre.toLowerCase() == "annat" ?
                            <p className="otherGenreText">Här kan du hitta recensioner inom genrer som till exempel Soul, Country, Ambient, Americana och Electronica.</p>
                            : ""    
                        }
                    <p className="recordsTotal">{recordData.length} recensioner</p>
                </div>
            </MediaQuery>

            <MediaQuery minWidth={601} maxWidth={1000}> {/* Both mobile and tablet */}
                <div className="recordsTopInfoTop">
                    <div className="recordsGenre">
                        { genre.toLowerCase() != "annat" ? 
                            genre.toUpperCase().charAt(0).toUpperCase() + //Capitalize first letter
                                                    genre.slice(1) + "recensioner"
                            : 
                            "Fler Genrer"
                        }
                           
                    </div>

                        {genre.toLowerCase() == "annat" ?
                            <p className="otherGenreText">Här kan du hitta recensioner inom genrer som till exempel Soul, Country, Ambient, Americana och Electronica.</p>
                            : ""    
                        }
                    <p className="recordsTotal">{recordData.length} recensioner</p>
                </div>
            </MediaQuery>
                
        
                <div className="recordsTopInfo">
                    <MediaQuery maxWidth={1000}> {/* Both mobile and tablet */}
                        <div className="recordsTopInfoLeft">
                            
                            <div className="recordsHamburgerMenu">
                                <MobileSortMenu  
                                    width='9.4vw'
                                    height='30px'
                                    iconSize='20px'
                                    marginLeft="0"
                                    setOpen={setSortMenuOpen}
                                    open={sortMenuOpen}
                                    setSortState={setSortState}
                                    trigger={
                                        <div className="mobileSortTrigger">
                                            {/*<i className="mobileSortIcon fa-solid fa-bars"></i>*/}
                                            <img src={SortIcon} className="mobileSortIcon" />
                                            <p>Sortera</p>
                                        </div>
                                    }
                                    /*trigger={
                                        <div className="recordsDropdownCurrentSort"> 
                                            <p style={{marginLeft:"0.5vw"}}> {sortState} </p>
                                            <DropdownArrow className="recordsDropdownArrow" />
                                        </div>
                                    }*/
                                    menu={[
                                        <div >BLUES</div>,
                                        <div >ROCK</div>,
                                        <div >JAZZ</div>,
                                        <div >FLER GENRER</div>,
                                        <div >OM OSS</div>
                                    ]}
                                    
                                />
                            </div>
                            
                        </div>
                    

                       
                    </MediaQuery>

                    <MediaQuery minWidth={1001} maxWidth={1599}>
                        <div className="recordsGenre">
                            { genre.toLowerCase() != "annat" ? 
                                genre.toUpperCase().charAt(0).toUpperCase() + //Capitalize first letter
                                                        genre.slice(1) + "recensioner"
                                : 
                                "Fler Genrer"
                            }
                           
                        </div>

                        {genre.toLowerCase() == "annat" ?
                            <p className="otherGenreText">Här kan du hitta recensioner inom genrer som till exempel Soul, Country, Ambient, Americana och Electronica.</p>
                            : ""    
                        }

                        <p className="recordsTotal">{recordData.length} recensioner</p>
                            <div className="recordsDropdown">
                                <Dropdown 
                                    width='245px'
                                    height='30px'
                                    iconSize='0.833vw'
                                    setOpen={setSortMenuOpen}
                                    open={sortMenuOpen}
                                    /*trigger={<i className="dropdownIcon fa-solid fa-bars"></i>}*/
                                    trigger={
                                        <div id={sortState} className="recordsDropdownSortTrigger"> 
                                            <img src={SortIcon} className="dropdownSortIcon" />

                                            <p className="recordsDropdownSortStateDisplay"> 
                                                {sortStateDisplayString} 
                                            </p>

                                            <img src={DownArrow} className="recordsDropdownSortArrow"/>
                                        </div>
                                    }
                                    menu={[
                                        <div id="latest" onClick={handleLatest}>Senaste recension</div>,
                                        <div id="artistAsc" onClick={handleArtistAscending}>Artist A-Ö</div>,
                                        <div id="artistDesc" onClick={handleArtistDescending}>Artist Ö-A</div>,
                                        <div id="recordAsc" onClick={handleRecordAscending}>Skiva A-Ö</div>,
                                        <div id="recordDesc" onClick={handleRecordDescending}>Skiva Ö-A</div>
                                    ]}
                                    
                                />
                            
                        </div>
                        
                    </MediaQuery>

                    <MediaQuery minWidth={1600}>
                    <div className="recordsGenre">
                            { genre.toLowerCase() != "annat" ? 
                                genre.toUpperCase().charAt(0).toUpperCase() + //Capitalize first letter
                                                        genre.slice(1) + "recensioner"
                                : 
                                "Fler Genrer"
                            }
                           
                        </div>

                        {genre.toLowerCase() == "annat" ?
                            <p className="otherGenreText">Här kan du hitta recensioner inom genrer som till exempel Soul, Country, Ambient, Americana och Electronica.</p>
                            : ""    
                        }
                        <p className="recordsTotal">{recordData.length} recensioner</p>
                        <div className="recordsDropdown">
                            <Dropdown 
                                width='245px'
                                height='30px'
                                iconSize='0.833vw'
                                setOpen={setSortMenuOpen}
                                open={sortMenuOpen}
                                /*trigger={<i className="dropdownIcon fa-solid fa-bars"></i>}*/
                                trigger={
                                    <div id={sortState} className="recordsDropdownSortTrigger"> 
                                        <img src={SortIcon} className="dropdownSortIcon" />

                                        <p className="recordsDropdownSortStateDisplay"> 
                                            {sortStateDisplayString} 
                                        </p>

                                        <img src={DownArrow} className="recordsDropdownSortArrow"/>
                                    </div>
                                }
                                menu={[
                                    <div id="latest" onClick={handleLatest}>Senaste recension</div>,
                                    <div id="artistAsc" onClick={handleArtistAscending}>Artist A-Ö</div>,
                                    <div id="artistDesc" onClick={handleArtistDescending}>Artist Ö-A</div>,
                                    <div id="recordAsc" onClick={handleRecordAscending}>Skiva A-Ö</div>,
                                    <div id="recordDesc" onClick={handleRecordDescending}>Skiva Ö-A</div>
                                ]}
                                
                            />
                            
                        </div>
                        

                    </MediaQuery>
                </div>
                
                <div className="recordSection">
                <MediaQuery maxWidth={600}>
                    {isLoading ? "Loading..." : currentTableData.map(item => {
                            return (
                                <div key={item[0]} className="recordSectionCard">
                                    <RecordCard 
                                        width="150px"
                                        height="150px"
                                        genre={genre}
                                        recordData={item}
                                        showLabels={false}
                                        fontSize="12px"
                                        smallFont="0.6em"
                                        recordFontSize="10px"

                                    />
                                </div>
                                
                            );
                            
                    })}
                </MediaQuery>

                <MediaQuery minWidth={601} maxWidth={1000}>
                    {isLoading ? "Loading..." : currentTableData.map(item => {
                            return (
                                <div key={item[0]} className="recordSectionCard">
                                    <RecordCard 
                                        width="310px"
                                        height="310px"
                                        genre={genre}
                                        recordData={item}
                                        showLabels={false}
                                        fontSize="22px"
                                        smallFont="0.6em"
                                        recordFontSize="18px"

                                    />
                                </div>
                                
                            );
                            
                    })} 
                </MediaQuery>

                <MediaQuery minWidth={1001} maxWidth={1599}>
                    {isLoading ? "Loading..." : currentTableData.map((item, index) => {

                    const numItemsInRow = Math.min(currentTableData.length - Math.floor(index / 5) * 5, 5);
                    const isLastRow = Math.floor(index / 5) === Math.floor((currentTableData.length - 1) / 5);
                    let marginRight = '0';

                    if (isLastRow && numItemsInRow < 5 && index === currentTableData.length - 1) {
                        const positionInRow = index % 5 + 1; // Position of the current item in the row (1-indexed)
                    
                        if(numItemsInRow == 2){
                            marginRight = 622.5
                        }
                        else if(numItemsInRow == 3){
                            marginRight = 415
                        }
                        else if(numItemsInRow == 4){
                            marginRight = 207.5
                        }
                    }
                        return (
                            <div key={item[0]} className="recordSectionCard" style={{ marginRight}}>
                                <RecordCard 
                                    width="180px"
                                    height="180px"
                                    genre={genre}
                                    recordData={item}
                                    showLabels={false}
                                    fontSize="14px"
                                    smallFont="0.6em"
                                    recordFontSize="11px"

                                />
                            </div>
                            
                        );
                            
                    })}
                </MediaQuery>

                <MediaQuery minWidth={1600}>
                    
                    {isLoading ? "Loading..." : currentTableData.map((item, index) => {

                    const numItemsInRow = Math.min(currentTableData.length - Math.floor(index / 5) * 5, 5);
                    const isLastRow = Math.floor(index / 5) === Math.floor((currentTableData.length - 1) / 5);
                    let marginRight = '0';

                    if (isLastRow && numItemsInRow < 5 && index === currentTableData.length - 1) {
                        const positionInRow = index % 5 + 1; // Position of the current item in the row (1-indexed)
                    
                        if(numItemsInRow == 2){
                            marginRight = 1005
                        }
                        else if(numItemsInRow == 3){
                            marginRight = 670
                        }
                        else if(numItemsInRow == 4){
                            marginRight = 335
                        }
                    }
                        return (
                            <div key={item[0]} className="recordSectionCard" style={{ marginRight}}>
                                <RecordCard 
                                    width="300px"
                                    height="300px"
                                    genre={genre}
                                    recordData={item}
                                    showLabels={false}
                                    currentPage={currentPage}

                                />
                            </div>
                            
                        );
                            
                    })}
                    </MediaQuery>
                        

                </div>
                {isLoading ? "Loading..." :
                    <div className="recordsPagination">
                        <Pagination  className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={recordData.length}
                            pageSize={PageSize}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                }
            
            <Footer />
        </div>
        
    )
           
}