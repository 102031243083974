import "./mobilewelcome.css"

import { Link } from "react-router-dom"
import { useContext } from "react"
import MediaQuery from "react-responsive"

export default function MobileWelcome( screenWidth ) {
    
    return (
        
        <div className="mobileWelcome">
           <div className="mobileWelcomeTitle">
                <p>Välkommen till Blockazz Records</p>
            </div>

            <div className="mobileWelcomeHorizontalLine"></div>

            <div className="mobileWelcomeText">
                    
                <p>
                    Blockazz Records är sidan som recenserar och tipsar om 
                    både nya och äldre skivor inom Blues, Rock, Jazz och 
                    andra genrer som till exempel Soul, Americana och New 
                    age. 
                </p>
                

                <p>
                    Vi som skriver på sidan är två bröder som med ett stort 
                    musikintresse vill dela med oss och inspirera andra. 
                    Samtliga skivor kommer från våra egna samlingar. Du 
                    kan läsa mer om bakgrunden och idén till sidan under 
                    fliken ”Om oss”. 
                
                </p>
                
                <p>
                    Trevlig läsning och lyssning!
                </p>
            </div>
            
            
        </div>
    )
}