import { cloneElement, useEffect, useRef, useState } from "react"
import "./mobileSortMenu.css"
import { Link, useLocation } from "react-router-dom";

import Xmark from "../../../assets/icons/xmark-gold.svg"

export default function MobileSortMenu(props){

    const wrapperRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false)
    const [menuChoice, setMenuChoice] = useState("latest")


    const locationData = useLocation();
    const { pathname } = locationData;
    const splitLocation = pathname.split("/");

    const handleOpen = () => {
        setMenuOpen(!menuOpen)
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

      const handleClickOutside = (e) => {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
            setMenuOpen(false)
        }
      };

      function sortReviews(){
        props.setSortState(menuChoice)
        setMenuOpen(!menuOpen)
      }

      function handleChange(e){
        setMenuChoice(e.target.value)
      }

    return (
        <div className="mobileSortMenu" ref={wrapperRef}
            style={{
                width:props.width,
                height:props.height,
                marginLeft:props.marginLeft
            }}
        >
            
            {cloneElement(props.trigger, {
                onClick: handleOpen,
                style: {
                    fontSize:props.iconSize
                }
            })}
            <div className="sortMenuContainer"
                style={{
                        
                    left: menuOpen ? "0vw" : "-70vw",
                    transition: "left 1s"
                }}
            >
                <div className="sortMenuListWrapper" >
                    <div className="sortMenuList" onChange={handleChange}>
                        <img src={Xmark} className="sortMenuCloseIcon"/>
                        <p>Sortera</p>
                        <div className="sortMenuHorizontalLine"></div>
                        <label className="container">Senaste recension
                            <input type="radio" value="latest" defaultChecked={true} name="radio"/>
                            <span className="checkmark"></span>
                        </label>

                        <label className="container">Artist A-Ö
                            <input type="radio" value="artistAsc" name="radio" />
                            <span className="checkmark"></span>
                        </label>

                        <label className="container">Artist Ö-A
                            <input type="radio" value="artistDesc" name="radio" />
                            <span className="checkmark"></span>
                        </label>

                        <label className="container">Skiva A-Ö
                            <input type="radio" value="recordAsc" name="radio" />
                            <span className="checkmark"></span>
                        </label>

                        <label className="container">Skiva Ö-A
                            <input type="radio" value="recordDesc" name="radio" />
                            <span className="checkmark"></span>
                        </label>

                        
                    </div>
                    <div className="sortMenuListButton" onClick={sortReviews}>
                            Visa recensioner
                    </div>
                </div>
            </div>
        </div>
    )
}