import { useQueries, useQuery } from "@tanstack/react-query"
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { makeRequest } from "../../axios";
import "./searchbar.css"

import baseUrl from "../../baseUrl"

import SearchIcon from "../../assets/icons/search-black.svg"

export default function SearchBar(props){

    const [searchField, setSearchField] = useState("")
    const navigate = useNavigate();

    const bluesQuery = useQuery(["blues_records"], () =>
        makeRequest.get("https://"+baseUrl+"/api/records", {mode: "cors", params: {genre: "blues"}}).then((res) => {
            return res.data;
        })
    );

    const rockQuery = useQuery(["rock_records"], () =>
        makeRequest.get("https://"+baseUrl+"/api/records", {mode: "cors", params: {genre: "rock"}}).then((res) => {
            return res.data;
        })
    );

    const jazzQuery = useQuery(["jazz_records"], () =>
        makeRequest.get("https://"+baseUrl+"/api/records", {mode: "cors", params: {genre: "jazz"}}).then((res) => {
            return res.data;
        })
    );

    const otherQuery = useQuery(["other_records"], () =>
        makeRequest.get("https://"+baseUrl+"/api/records", {mode: "cors", params: {genre: "annat"}}).then((res) => {
            return res.data;
        })
    );

    /*const bluesQuery = useQuery(["blues_records"])
    const rockQuery = useQuery(["rock_records"])
    const jazzQuery = useQuery(["jazz_records"])
    const otherQuery = useQuery(["other_records"])
*/
    const bluesArr = bluesQuery.data?.map((data) => data)
    const rockArr = rockQuery.data?.map((data) => data)
    const jazzArr = jazzQuery.data?.map((data) => data)
    const otherArr = otherQuery.data?.map((data) => data)

    let allArr;
    if(bluesArr && rockArr && jazzArr && otherArr) {
        allArr = [...bluesArr, ...rockArr, ...jazzArr, ...otherArr]
    }

    if(!allArr)
        return

    const filteredArr = allArr.filter(
        record => {
            
            return(
                record[1].toLowerCase().includes(searchField.toLowerCase()) ||
                record[2].toLowerCase().includes(searchField.toLowerCase())
            )
        }
    )

    const handleEnter = (e) => {
        if(e.key == 'Enter'){
            props.setSearchResult(filteredArr)
            props.setSearchField(searchField)
            //navigate("/search", {state:{data:{records:filteredArr, searchField:searchField}}})
        }
    }

    function handleClick()  {
        
        props.setSearchResult(filteredArr)
        props.setSearchField(searchField)
        //navigate("/search", {state:{data:{records:filteredArr, searchField:searchField}}})
        
    }

    return(
        <div className="searchBar">
            <input className="searchBarInput" type="text" placeholder="Sök efter artist eller album" onKeyDown={handleEnter} onChange={(e) => setSearchField(e.target.value)} />
            <div className="searchBarLink" onClick={handleClick}>
                <img src={SearchIcon} />
                
            </div>
        </div>
        
        
    )
}