import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { makeRequest } from "../../../axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import "./add.css"
import Topbar from "../../../components/topbar/Topbar"
import { Editor, EditorState } from "draft-js";
import "draft-js/dist/Draft.css";
import TextEditor from "../../../components/texteditor/TextEditor";
import Navbar from "../../../components/Navbar/Navbar";
import { AuthContext } from "../../../context/authContext";
import Footer from "../../../components/footer/Footer";
import Dropdown from "../../../components/Dropdown/Dropdown";
import moment from "moment";

import baseUrl from "../../../baseUrl"

import DownArrow from "../../../assets/icons/nav-arrow-down-black.svg";
import MediaQuery from "react-responsive";

const Add = () => {
    const {user, logout} = useContext(AuthContext)

    const [file, setFile] = useState(null);
    const [artist, setArtist] = useState("");
    const [recordName, setRecordName] = useState("");
    const [genre, setGenre] = useState("BLUES");
    const [releaseYear, setReleaseYear] = useState("");
    const [playTime, setPlayTime] = useState("");
    const [review, setReview] = useState("");
    const [date, setDate] = useState("");
    const [subGenre, setSubGenre] = useState("");

    const [imagePreview, setImagePreview] = useState(null);

    //const [sortStateDisplayString, setSortStateDisplayString] = useState("");
    const [genreMenuOpen, setGenreMenuOpen] = useState(false)

    const navigate = useNavigate();
    /*useEffect(() => {
        navigate(
            "/",
            {
                state: {
                    artist: "Asia",
                    record: "Phoenix"
                }
            }
        )
    })*/
    

    const handleCallback = (childData) => {
        setReview(JSON.stringify(childData))
    }

    
    const removeImage = (e) => {
        setFile(null)
    }
    

    const config = {     
        headers: { 'content-type': 'multipart/form-data' }
    }

    const upload = async () => {
        try {
          const formData = new FormData();
          formData.append("file", file);
          const res = await makeRequest.post("https://"+baseUrl+"/api/upload", formData);
          return res.data;
        } catch (err) {
          console.log(err);
        }
    };
    
    const queryClient = useQueryClient();

    const mutation = useMutation(
        (newPost) => {
          return makeRequest.post("https://"+baseUrl+"/api/records", newPost);
        },
        {
          onSuccess: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries(["records"]);

            navigate(
                "/",
                {
                    state: {
                        artist:artist,
                        record: recordName
                    }
                }
            )
          },
        }
    );

    const handleClick = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        let imgUrl = "";
        setDate(moment().format("YYYY-MM-DD"))
        console.log(file)
        if(!file) console.log("DSFG")
        if (file) imgUrl = await upload();
        mutation.mutate({ artist:artist, recordName:recordName, genre:genre.toLowerCase(), image:imgUrl, review:review, releaseYear:releaseYear, playTime:playTime, reviewedAt:date, reviewedBy:user.username, subGenre:subGenre.toLowerCase()});
        setArtist("");
        setRecordName("");
        setFile(null);

      };

      const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
          setImagePreview(URL.createObjectURL(event.target.files[0]));
        }
       }

       let editorState = EditorState.createEmpty()

    return (
        <div className="add">
            <Navbar  />

            <MediaQuery minWidth={1001} maxWidth={1599} >
            <div className="addForm">
                    <p className="addFormHeader">Lägg till en ny recension</p>
                    <div className="addFormTop">

                        <div className="addFormTopLeft">

                            <p className="addFormFieldDesc">Artist/Grupp</p>
                            <input className="largeInput" type="text" placeholder="Skriv din text här..." onChange={(e) => setArtist(e.target.value)} name="artist" />

                            <p className="addFormFieldDesc">Album</p>
                            <input className="largeInput" type="text" placeholder="Skriv din text här..." onChange={(e) => setRecordName(e.target.value)} name="recordName" />

                            <div className="addFormTopHorizontal">
                                <div className="addFormLeftVertical">

                                <p>Genre</p>
                                <div className="addFormDropdown">
                                    <Dropdown 
                                        width='157px'
                                        height='45px'
                                        iconSize='0.833vw'
                                        setOpen={setGenreMenuOpen}
                                        open={genreMenuOpen}
                                        /*trigger={<i className="dropdownIcon fa-solid fa-bars"></i>}*/
                                        trigger={
                                            <div id={genre} className="addFormDropdownTrigger"> 
                                                
                                                <p className="recordsDropdownSortStateDisplay"> 
                                                    {genre == "ANNAT" ? "FLER GENRER" : genre} 
                                                </p>

                                                <img src={DownArrow} className="recordsDropdownSortArrow"/>
                                            </div>
                                        }
                                        menu={[
                                            <div id="BLUES" onClick={(e) => setGenre("BLUES")} >BLUES</div>,
                                            <div id="ROCK"  onClick={(e) => setGenre("ROCK")} >ROCK</div>,
                                            <div id="JAZZ"  onClick={(e) => setGenre("JAZZ")} >JAZZ</div>,
                                            <div id="ANNAT" onClick={(e) => setGenre("ANNAT")} >FLER GENRER</div>,
                                        ]}
                                        
                                    />
                                    
                                </div>



                                    
                                    
                                </div>

                                <div className="addFormLeftVertical">
                                    <p>Releaseår</p>
                                    <input className="smallInput" type="text" placeholder="ÅÅÅÅ" onChange={(e) => setReleaseYear(e.target.value)} name="releaseYear" />
                                </div>
                                <div className="addFormLeftVertical">
                                    <p>Speltid</p>
                                    <input className="smallInput" type="text" placeholder="00:00" onChange={(e) => setPlayTime(e.target.value)} name="playTime" />
                                </div>

                                {/*<div className="addFormLeftVertical">
                                    <p>DATUM</p>
                                    <input className="smallInput" type="text" placeholder="ÅÅÅÅ-MM-DD" onChange={(e) => setDate(e.target.value)} name="playTime" />
                                </div>*/}
                            </div>

                            {genre == "ANNAT" ? 
                                <>
                                    <p style={{marginTop:"20px"}} >Etikett</p>
                                    <input className="subGenreInput" type="text" 
                                        value={subGenre} 
                                        placeholder="Skriv din etikett här..." 
                                        onChange={(e) => setSubGenre(e.target.value)} 
                                        name="subGenre" 
                                    /> 
                                </>
                                : null
                            
                            }
                        </div>
                        <div className="addFormTopRight">
                            <div className="addFormImage">
                                <div className="addImage">
                                    <input type="file" id="file" style={{display:"none"}} onChange={(e) => setFile(e.target.files[0])} name="image" />
                                    {file ? 
                                        <div className="addImagePreview"> 
                                            
                                            <img className="previewImage" src={URL.createObjectURL(file)} />
                                            <i className="addCloseImageIcon fa-solid fa-xmark" onClick={removeImage}></i>
                                        </div>
                                        : <label className="addIconText" htmlFor="file">
                                            <i className="addImageIcon fa-solid fa-plus"></i>
                                            <span>LÄGG TILL BILD</span>
                                            
                                        </label>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="addFormBottom">
                        <div className="addFormEditor">
                            <TextEditor editorState={editorState} className="addFormReviewInput" parentCallback={handleCallback} />
                            {/*
                            <textarea className="addFormReviewInput" placeholder="Skriv din text här..." onChange={(e) => setReview(e.target.value)} />
                            */}
                            
                        </div>
                        
                            
                    </div>
                    <button className="addReviewButton" type={"button"} onClick={handleClick}>Publicera</button>
                    
                </div>
            </MediaQuery>

            <MediaQuery minWidth={1600} >
                <div className="addForm">
                    <div className="addFormTop">

                        <p className="addFormHeader">Lägg till en ny recension</p>

                        <p className="addFormFieldDesc">Artist/Grupp</p>
                        <input className="largeInput" type="text" placeholder="Skriv din text här..." onChange={(e) => setArtist(e.target.value)} name="artist" />

                        <p className="addFormFieldDesc">Album</p>
                        <input className="largeInput" type="text" placeholder="Skriv din text här..." onChange={(e) => setRecordName(e.target.value)} name="recordName" />

                        <div className="addFormTopHorizontal">
                            <div className="addFormLeftVertical">

                            <p>Genre</p>
                            <div className="addFormDropdown">
                                <Dropdown 
                                    width='180px'
                                    height='55px'
                                    iconSize='0.833vw'
                                    setOpen={setGenreMenuOpen}
                                    open={genreMenuOpen}
                                    /*trigger={<i className="dropdownIcon fa-solid fa-bars"></i>}*/
                                    trigger={
                                        <div id={genre} className="addFormDropdownTrigger"> 
                                            
                                            <p className="recordsDropdownSortStateDisplay"> 
                                                {genre == "ANNAT" ? "FLER GENRER" : genre} 
                                            </p>

                                            <img src={DownArrow} className="recordsDropdownSortArrow"/>
                                        </div>
                                    }
                                    menu={[
                                        <div id="BLUES" onClick={(e) => setGenre("BLUES")} >BLUES</div>,
                                        <div id="ROCK"  onClick={(e) => setGenre("ROCK")} >ROCK</div>,
                                        <div id="JAZZ"  onClick={(e) => setGenre("JAZZ")} >JAZZ</div>,
                                        <div id="ANNAT" onClick={(e) => setGenre("ANNAT")} >FLER GENRER</div>,
                                    ]}
                                    
                                />
                                
                            </div>



                                
                                
                            </div>

                            <div className="addFormLeftVertical">
                                <p>Releaseår</p>
                                <input className="smallInput" type="text" placeholder="ÅÅÅÅ" onChange={(e) => setReleaseYear(e.target.value)} name="releaseYear" />
                            </div>
                            <div className="addFormLeftVertical">
                                <p>Speltid</p>
                                <input className="smallInput" type="text" placeholder="00:00" onChange={(e) => setPlayTime(e.target.value)} name="playTime" />
                            </div>

                            {/*<div className="addFormLeftVertical">
                                <p>DATUM</p>
                                <input className="smallInput" type="text" placeholder="ÅÅÅÅ-MM-DD" onChange={(e) => setDate(e.target.value)} name="playTime" />
                            </div>*/}
                        </div>

                        {genre == "ANNAT" ? 
                            <>
                                <p style={{marginTop:"20px"}} >Etikett</p>
                                <input className="subGenreInput" type="text" 
                                    value={subGenre} 
                                    placeholder="Skriv din etikett här..." 
                                    onChange={(e) => setSubGenre(e.target.value)} 
                                    name="subGenre" 
                                /> 
                            </>
                            : null
                        
                        }
                            
                    </div>
                    <div className="addFormBottom">
                        <div className="addFormEditor">
                            <TextEditor editorState={editorState} className="addFormReviewInput" parentCallback={handleCallback} />
                            {/*
                            <textarea className="addFormReviewInput" placeholder="Skriv din text här..." onChange={(e) => setReview(e.target.value)} />
                            */}
                            
                        </div>
                        <div className="addFormImage">
                            <div className="addImage">
                                <input type="file" id="file" style={{display:"none"}} onChange={(e) => setFile(e.target.files[0])} name="image" />
                                {file ? 
                                    <div className="addImagePreview"> 
                                        
                                        <img className="previewImage" src={URL.createObjectURL(file)} />
                                        <i className="addCloseImageIcon fa-solid fa-xmark" onClick={removeImage}></i>
                                    </div>
                                    : <label className="addIconText" htmlFor="file">
                                        <i className="addImageIcon fa-solid fa-plus"></i>
                                        <span>LÄGG TILL BILD</span>
                                        
                                    </label>
                                }
                            </div>
                        </div>
                            
                    </div>
                    <button className="addReviewButton" type={"button"} onClick={handleClick}>Publicera</button>
                    
                </div>
            </MediaQuery>
            
            <Footer />
        </div>
    )
}

export default Add;