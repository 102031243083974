import { cloneElement, useEffect, useRef, useState } from "react"
import "./hamburgermenu.css"
import { Link, useLocation } from "react-router-dom";

import Xmark from "../../assets/icons/xmark-gold.svg";

export default function HamburgerMenu(props){

    const wrapperRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false)

    const locationData = useLocation();
    const { pathname } = locationData;
    const splitLocation = pathname.split("/");

    const handleOpen = () => {
        setMenuOpen(!menuOpen)
    }
    const handleScroll = () => {
        window.scrollTo({top: 4500, left: 0, behavior: 'smooth'});
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

      const handleClickOutside = (e) => {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
            setMenuOpen(false)
        }
      };

    return (
        <div className="hamburgerMenu" ref={wrapperRef}
            style={{
                width:props.width,
                height:props.height,
                marginLeft:props.marginLeft
            }}
        >
            
            {cloneElement(props.trigger, {
                onClick: handleOpen,
                style: {
                    boxShadow: menuOpen ? "0 5px 10px grey" : "",
                    fontSize:props.iconSize
                }
            })}
            <div className="menuContainer"
                style={{
                        
                    left: menuOpen ? "0vw" : "-70vw",
                    transition: "left 1s"
                }}
            >
               
                <ul className="menuList" 
                   
                >
                    <li className="menuItem" onClick={handleOpen}> <img src={Xmark} /></li>
                    <li onClick={handleOpen} className={["menuItem", splitLocation[1] === "blues" ? "mobileActive" : ""].join(' ')}> 
                            <Link className="link" to="/blues">BLUES</Link> 
                    </li>
                    <li onClick={handleOpen} className={["menuItem", splitLocation[1] === "rock" ? "mobileActive" : ""].join(' ')}> 
                        <Link className="link" to="/rock">ROCK</Link>
                    </li>
                    <li onClick={handleOpen} className={["menuItem", splitLocation[1] === "jazz" ? "mobileActive" : ""].join(' ')}> 
                        <Link className="link" to="/jazz">JAZZ</Link>
                    </li>
                    <li onClick={handleOpen} className={["menuItem", splitLocation[1] === "annat" ? "mobileActive" : ""].join(' ')}> 
                        <Link className="link" to="/annat">FLER GENRER</Link>
                    </li>
                    <li onClick={handleScroll} className="menuItem"> 
                        <a className="link">VÅRA FAVORITER</a>
                    </li>
                    <li className={["menuItem", splitLocation[1] === "about" ? "mobileActive" : ""].join(' ')}> 
                        <Link className="link" to="/about">OM OSS</Link>
                    </li>

                    
                </ul>
            </div>
        </div>
    )
}