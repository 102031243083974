import { cloneElement, useEffect, useRef, useState } from "react"
import "./dropdown.css"

export default function Dropdown(props){

    const wrapperRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false)
    

    const handleOpen = () => {
        setMenuOpen(!menuOpen)
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
        
    }, []);

      const handleClickOutside = (e) => {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
            setMenuOpen(false)
        }
      };      

    return (
        <div className="dropdown" ref={wrapperRef}
            style={{
                width:props.width,
                height:props.height,
                marginLeft:props.marginLeft
            }}
        >
            
            {cloneElement(props.trigger, {
                onClick: handleOpen,
                style: {
                    
                    fontSize:props.iconSize
                }
            })}
            {menuOpen &&
                <ul className="dropdownList" >
                    {props.menu.filter((menuItem) => menuItem.props.id != props.trigger.props.id).map((menuItem, index,) => (
                        <div key={index} className="dropdownItemContainer">
                            <li key={index} className="dropdownItem">
                                {cloneElement(menuItem, {
                                    onClick: () => {
                                        menuItem.props.onClick()
                                        setMenuOpen(false)
                                    },
                                })}
                                
                            </li>
                        </div>
                    ))}
                </ul>
            }
        </div>
    )
}