import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { makeRequest } from "../axios";

import baseUrl from "../baseUrl"

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [wait, setWait] = useState(false)

  const loginUser = async ({email, password}) => {
    setWait(true)
    try{
      const {data} = await makeRequest.post("https://"+baseUrl+"/api/login", {email, password})

      if(data.success && data.token){
        localStorage.setItem('loginToken', data.token);
        makeRequest.defaults.headers.common['Authorization'] = 'Bearer ' + data.token; // Set Authorization header
        setWait(false);
        return {success:1};
      }
      setWait(false);
      return {success:0, message:data.message};
    }
    catch(err){
      setWait(false);
      return {success:0, message:'Server Error!'};
    }
  }

  const loggedInCheck = async () => {
    const loginToken = localStorage.getItem('loginToken');
    if(loginToken){
      
      makeRequest.defaults.headers.common['Authorization'] = 'Bearer ' + loginToken; // Set Authorization header
      try {
        const {data} = await makeRequest.get("https://"+baseUrl+"/api/getuser");
        if(data.success && data.user){
          setUser(data.user);
          return;
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
    setUser(null);
  }

  useEffect(() => {
    loggedInCheck();
  },[]);

  const logout = () => {
    localStorage.removeItem('loginToken');
    delete makeRequest.defaults.headers.common['Authorization']; // Remove Authorization header
    setUser(null);
  }

  return (
    <AuthContext.Provider value={{ loginUser,wait, user:user,loggedInCheck,logout }}>
      {children}
    </AuthContext.Provider>
  );
};