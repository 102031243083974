import { useState } from "react"
import "./register.css"
import axios from "axios"
import { makeRequest } from "../../../axios";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import baseUrl from "../../../baseUrl"

const Register = () => {

    const [user, setUser] = useState({
        username: "",
        password: "",
        email: "",
        profilepic: ""
    });

    const [err, setErr] = useState(null)

    const handleChange = (e) => {
        setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const queryClient = useQueryClient();

    const mutation = useMutation(
        (newUser) => {
            console.log(newUser)
          return makeRequest.post("https://"+baseUrl+"/api/jwtregister", newUser);
        },
        {
          onSuccess: () => {
            // Invalidate and refetch
            console.log("success")
            queryClient.invalidateQueries(["users"]);
          },
        }
    );

    const handleClick = async (e) => {
        e.preventDefault()
        let a = mutation.mutate({ username:user.username, password:user.password, email:user.email })
        console.log(a)
    }
    return (
        <div className="registerForm">
            <h1>Register new user</h1>
            <input type="text" placeholder="Username..." onChange={handleChange} name="username" />
            <input type="password" placeholder="***" onChange={handleChange} name="password" />
            <input type="text" placeholder="email..." onChange={handleChange} name="email" />

            <button onClick={handleClick}>Register</button>
            {err && err}
            
        </div>
    )
}

export default Register;