import { convertToRaw, Editor, EditorState, getDefaultKeyBinding, RichUtils } from 'draft-js';
import React from 'react'
import "./texteditor.css"

import BoldIcon from "../../assets/icons/bold-black.svg";
import ItalicIcon from "../../assets/icons/italic-black.svg";
import UnderlineIcon from "../../assets/icons/underline-black.svg";

class TextEditor extends React.Component {
    constructor(props) {
      super(props);
      this.state = {editorState: this.props.editorState};
      this.text = "";

      //this.focus = () => this.refs.editor.focus();
      //this.onChange = (editorState) => this.setState({editorState});
      this.onChange = (editorState) => {
        //this.text = editorState.getCurrentContent().getPlainText('\u0001')
        this.props.parentCallback(convertToRaw(editorState.getCurrentContent()));
        //this.text = convertToRaw(editorState.getCurrentContent())
        //console.log(this.text)
        this.setState({editorState});
      }

      this.handleKeyCommand = this._handleKeyCommand.bind(this);
      this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
      this.toggleBlockType = this._toggleBlockType.bind(this);
      this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
    }

    _handleKeyCommand(command, editorState) {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        this.onChange(newState);
        return true;
      }
      return false;
    }

    _mapKeyToEditorCommand(e) {
      if (e.keyCode === 9 /* TAB */) {
        const newEditorState = RichUtils.onTab(
          e,
          this.state.editorState,
          4, /* maxDepth */
        );
        if (newEditorState !== this.state.editorState) {
          this.onChange(newEditorState);
        }
        return;
      }
      return getDefaultKeyBinding(e);
    }

    _toggleBlockType(blockType) {
      this.onChange(
        RichUtils.toggleBlockType(
          this.state.editorState,
          blockType
        )
      );
    }

    _toggleInlineStyle(inlineStyle) {
      this.onChange(
        RichUtils.toggleInlineStyle(
          this.state.editorState,
          inlineStyle
        )
      );
    }

    render() {
      const {editorState} = this.state;

      // If the user changes block type before entering any text, we can
      // either style the placeholder or hide it. Let's just hide it now.
      let className = 'RichEditor-editor';
      var contentState = editorState.getCurrentContent();
      if (!contentState.hasText()) {
        if (contentState.getBlockMap().first().getType() !== 'unstyled') {
          className += ' RichEditor-hidePlaceholder';
        }
      }

      return (
        <div className="RichEditor-root">
          
          <EditorControls
            editorState={editorState}
            onToggleBlock={this.toggleBlockType}
            onToggleInline={this.toggleInlineStyle}
          />
            
           
          <div className={className} onClick={this.focus}
          style={{
            paddingLeft:"55px",
            paddingRight:"55px",
            fontFamily:"Noto Sans",
            fontSize:"20px"
          }}
          >
            
            <Editor
              
              blockStyleFn={getBlockStyle}
              customStyleMap={styleMap}
              editorState={editorState}
              handleKeyCommand={this.handleKeyCommand}
              keyBindingFn={this.mapKeyToEditorCommand}
              onChange={this.onChange}
              placeholder="Skriv din recension här..."
             
              spellCheck={true}
            />
          </div>
        </div>
      );
    }
  }

  // Custom overrides for "code" style.
  const styleMap = {
    CODE: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
  };

  function getBlockStyle(block) {
    switch (block.getType()) {
      case 'blockquote': return 'RichEditor-blockquote';
      default: return null;
    }
  }

  class StyleButton extends React.Component {
    constructor() {
      super();
      this.onToggle = (e) => {
        e.preventDefault();
        this.props.onToggle(this.props.style);
      };
    }

    render() {
      let className = 'RichEditor-styleButton';
      if (this.props.active) {
        className += ' RichEditor-activeButton';
      }

      return (
        <span className={className} onMouseDown={this.onToggle}>
          {this.props.label}
        </span>
      );
    }
  }

  const BLOCK_TYPES = [
    {label: 'H1', style: 'header-one'},
    {label: 'H2', style: 'header-two'},
    {label: 'H3', style: 'header-three'},
    {label: 'H4', style: 'header-four'},
    {label: 'H5', style: 'header-five'},
    {label: 'Blockquote', style: 'blockquote'},
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'},
    
  ];

  /*const BlockStyleControls = (props) => {
    const {editorState} = props;
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    return (
      <div className="RichEditor-controls">
        {BLOCK_TYPES.map((type) =>
          <StyleButton
            key={type.label}
            active={type.style === blockType}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        )}
      </div>
    );
  };*/

  var INLINE_STYLES = [
    {label: <img key="bold" src={BoldIcon} />, style: 'BOLD'},
    {label: <img key="italic" src={ItalicIcon} />, style: 'ITALIC'},
    {label: <img src={UnderlineIcon} />, style: 'UNDERLINE'},
  ];

  const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();
    
    return (
      <div className="RichEditor-controls">
        {INLINE_STYLES.map((type) =>
          <StyleButton
            key={type.label}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        )}
      </div>
    );
  };

  const EditorControls = (props) => {
    const {editorState} = props;
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

      const currentStyle = props.editorState.getCurrentInlineStyle();

    return(
      <div className="RichEditor-controls">
        {/*BLOCK_TYPES.map((type) =>
          <StyleButton
            key={type.label}
            active={type.style === blockType}
            label={type.label}
            onToggle={props.onToggleBlock}
            style={type.style}
          />
    )*/}
        {INLINE_STYLES.map((type, index) =>
          <StyleButton

            key={index}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={props.onToggleInline}
            style={type.style}
          />
        )}
      </div>
    )
  }

  export default TextEditor